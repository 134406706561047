import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch';

let submitButton = document.querySelector('.searchel-submit');

let currentPage = '';
if(document.body.classList.contains('style-ekm')) {
	currentPage = 'ekm_website_de';
}
if(document.body.classList.contains('style-eko')) {
	currentPage = 'eko_website_de';
}
if(document.body.classList.contains('style-ategris')) {
	currentPage = 'ategris_de';
}
if(document.body.classList.contains('style-wohnstifte')) {
	currentPage = 'wohnstifte_de';
}
if(document.body.classList.contains('style-dichterviertel')) {
	currentPage = 'wohnstifte_de';
}
if(document.body.classList.contains('style-uhlenhorst')) {
	currentPage = 'wohnstifte_de';
}
if(document.body.classList.contains('style-raadt')) {
	currentPage = 'wohnstifte_de';
}
if(document.body.classList.contains('style-intensivpflege')) {
	currentPage = 'wohnstifte_de';
}
if(document.body.classList.contains('style-bzmo')) {
	currentPage = 'eko_bzmo_live';
}
if(document.body.classList.contains('style-gutgerne')) {
	currentPage = 'eko_gutgerne_live';
}
if(document.body.classList.contains('style-hospiz')) {
	currentPage = 'eko_hospiz_live';
}

let currentKey = '';
if(document.body.classList.contains('style-ekm')) {
	currentKey = algoliasearch('FBXTDHEIF0', '297ec3ba6ad67bd65a5c37f896c84be6');
}
if(document.body.classList.contains('style-eko')) {
	currentKey = algoliasearch('ZI16HEF16W', 'eaac165c65b5706bff2b08f22a749183');
}
if(document.body.classList.contains('style-ategris')) {
	currentKey = algoliasearch('9T4MOS16SG', '67b308f02a2bd8cb4dce150122bb398f');
}
if(document.body.classList.contains('style-intra')) {
	currentKey = algoliasearch('L8MB36VE2R', 'cf3c7b8e2a415fed861d4c1a2565bcdf');
}
if(document.body.classList.contains('style-wohnstifte')) {
	currentKey = algoliasearch('7KBC4JR010', 'aee44af4d528a92d8ccd9ae1fd9b68ad');
}
if(document.body.classList.contains('style-dichterviertel')) {
	currentKey = algoliasearch('7KBC4JR010', 'aee44af4d528a92d8ccd9ae1fd9b68ad');
}
if(document.body.classList.contains('style-uhlenhorst')) {
	currentKey = algoliasearch('7KBC4JR010', 'aee44af4d528a92d8ccd9ae1fd9b68ad');
}
if(document.body.classList.contains('style-raadt')) {
	currentKey = algoliasearch('7KBC4JR010', 'aee44af4d528a92d8ccd9ae1fd9b68ad');
}
if(document.body.classList.contains('style-intensivpflege')) {
	currentKey = algoliasearch('7KBC4JR010', 'aee44af4d528a92d8ccd9ae1fd9b68ad');
}
if(document.body.classList.contains('style-bzmo')) {
	currentKey = algoliasearch('JFLG6J8S4W', 'd8deda1cd5424751bb24cb96b03e4e87');
}
if(document.body.classList.contains('style-gutgerne')) {
	currentKey = algoliasearch('G0NGVDKHR7', 'f9418370ad88a10d0b8256a6ab1ca56a');
}
if(document.body.classList.contains('style-hospiz')) {
	currentKey = algoliasearch('4RWA5CI2L9', 'fdc922b885d417f3735563e61945f8e0');
}

const searchClient = currentKey;

function buildSearchPageQuery(state) {
    if(state.query.length <= 0) {
        return null;
    }

    let baseurl = window.location.protocol + '//' + window.location.host;
    let searchPage = document.querySelector('#autocomplete').dataset.searchPage;
    let url = baseurl + searchPage + '?query='+ encodeURIComponent(state.query);
    url = url.replace(/\+/g, '%20');

    return url;
}

function updateOnStateChange(state) {

    let count = 0;
    state.collections.forEach(collection => {count += collection.items.length})

    let url = buildSearchPageQuery(state);

    submitButton.href = count > 0 ? url : '/suche';
}

export function initSiteSearch () {

    document.addEventListener('keydown', function (e) {
        if (e.key === 'Enter') {
            let activeItem = document.querySelector('.aa-Item[aria-selected="true"] a');
            let searchOpened = document.querySelector('li.search.is-clicked');
            if(activeItem && searchOpened) {
                window.location.href = activeItem.href;
            }
        }
    });

    autocomplete({
        container: '#autocomplete',
        placeholder: 'Was suchen Sie?',
        openOnFocus: false,
        autoFocus: true,
        detachedMediaQuery: 'none',
        panelContainer: '#ac-results',
        panelPlacement: 'input-wrapper-width',
        debug: true,

        onStateChange({ state }) {
            updateOnStateChange(state);
        },

        onSubmit({state, event}) {

            let url = buildSearchPageQuery(state);
            console.log(url);

            console.log(state);


            // sendEvent('searchfield', 'submit', state.query);

            window.location.href = url;
        },

        getSources({ query }) {
            return [
                {
                    sourceId: 'pages',
                    getItems() {
                        return getAlgoliaResults({
                            searchClient,
                            queries: [
                                {
                                    indexName: currentPage,
                                    query,
                                    params: {
                                        hitsPerPage: 10,
                                        attributesToSnippet: ['name:10', 'content:15'],
                                        snippetEllipsisText: '…',
                                    },
                                },
                            ],
                        });
                    },

                    templates: {
                        item({ item, components, html }) {
                            return html`
                                <a class="aa-ItemContentBody" href="${item.path}">
                                    <div class="aa-ItemContentTitle">
                                        ${components.Highlight({hit: item, attribute: 'title', tagName: 'em'})}
                                    </div>
                                    <div class="aa-ItemContentDescription">
                                        ${components.Snippet({hit: item, attribute: 'content', tagName: 'em'})}
                                    </div>
                                </a>
                            `;
                        },

                        // noResults() {
                        //     return 'No results.';
                        // },
                    },

                    getItemUrl({ item }) {
                        return item.url;
                    },

                },
            ];
        },
    });

}
